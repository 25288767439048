<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Postavshik kontrakt o'zgartirish</h3>
          </div>
        </div>
        <div class="card-body">
          <div class="input__wrapper">
            <div class="col-4">
              <v-combobox
                v-model="test"
                :items="getAllProviders"
                dense
                v-debounce:400ms="myFnn"
                item-value="id"
                item-text="full__name"
                label="Postavshik nomi"
                outlined
              ></v-combobox>
            </div>

            <div class="col-2">
              <v-text-field
                v-model="getDetails.contract_number"
                label="Shartnoma nomeri"
                outlined
                dense
              ></v-text-field>
            </div>

            <div class="col-3">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="start_date"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="start_date"
                    outlined
                    label="Shartnoma boshlang'ich sanasi"
                    append-icon="event"
                    readonly
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="start_date" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false"
                    >Yopish</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(start_date)"
                    >Saqlash</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </div>
            <div class="col-3">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                :return-value.sync="end_date"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="end_date"
                    outlined
                    append-icon="event"
                    label="Shartnoma tugash sanasi"
                    readonly
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="end_date" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu1 = false"
                    >Yopish</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu1.save(end_date)"
                    >Saqlash</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </div>

            <div class="col-3 pl-0">
              <v-text-field
                v-model="getDetails.amount"
                label="Summa"
                outlined
                dense
                v-currency="options"
              ></v-text-field>
            </div>
          </div>
          <div class="d-flex justify-content-start border-top pt-10">
            <div class="mr-2">
              <button
                type="button"
                class="
                  btn btn-light-primary
                  font-weight-bold
                  text-uppercase
                  px-9
                  py-4
                "
                @click="save"
              >
                Yuborish
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
// import { required } from "vuelidate/lib/validators";
// import Swal from "sweetalert2";

export default {
  data() {
    return {
      start_date: '',
      end_date: '',
      test: '',
      items: [
        { state: 'Muddatli', abbr: 'MU' },
        { state: 'Muddati cheklanmagan', abbr: 'MS' },
        { state: "Summaga bog'liq", abbr: 'SU' }
      ],
      items2: [
        { state: 'Aktiv', abbr: true },
        { state: 'Aktiv emas', abbr: false }
      ],
      menu: false,
      menu1: false
    }
  },
  beforeCreate() {
    // this.$store.dispatch("getAllProvidersContract");
    this.$store.dispatch('getProviderContractdetail', this.$route.params.id)
  },
  watch: {
    getDetails: function (val) {
      // console.log(val)
      if (val.start_date !== undefined && val.end_date !== undefined) {
        this.start_date = val.start_date
        this.end_date = val.end_date
        if (this.test.id == undefined) {
          this.test = {
            id: this.getDetails.client
          }
          this.test.full__name =
            this.getDetails.provider_name +
            ', INN:' +
            this.getDetails.provider_inn
        }
      }
    }
  },
  computed: {
    options() {
      return {
        locale: 'zh-ZH',
        currency: [
          this.currencyCode,
          null,
          { prefix: this.prefix, suffix: this.suffix }
        ][this.selectedCurrencyOption],
        valueRange: this.valueRangeEnabled
          ? { min: this.valueRange[0], max: this.valueRange[1] }
          : undefined,
        precision: this.precisionEnabled
          ? this.precisionRangeEnabled
            ? { min: this.precisionRange[0], max: this.precisionRange[1] }
            : this.precisionFixed
          : undefined,
        distractionFree: this.distractionFree
          ? {
              hideNegligibleDecimalDigits: this.hideNegligibleDecimalDigits,
              hideCurrencySymbol: this.hideCurrencySymbol,
              hideGroupingSymbol: this.hideGroupingSymbol
            }
          : false,
        autoDecimalMode: this.autoDecimalMode,
        valueAsInteger: this.valueAsInteger,
        allowNegative: this.allowNegative
      }
    },
    getAllProviders() {
      return this.$store.getters.providerSearchResult
    },
    getDetails() {
      const data = this.$store.state.requests.providerContractDetail
      data.amount = data.amount.toLocaleString('zh-ZH')
      if (data.provider_inn == '') {
        data.provider_inn = 'Kiritilmagan'
      }
      return data
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Postavshiklar' },
      { title: "Postavshik kontrakt o'zgartirish" }
    ])
  },
  methods: {
    myFnn(val) {
      const data = {
        name: val
      }
      this.$store.dispatch('providerSearch', data)
    },
    save() {
      const data = {
        end_date: this.end_date,
        start_date: this.start_date,
        amount: parseFloat(this.getDetails.amount.replace(/,/g, '')),
        contract_number: this.getDetails.contract_number
      }
      if (this.test.hasOwnProperty('id') == false) {
        data.client = this.getDetails.client
      } else if (this.test.hasOwnProperty('id') == true) {
        data.client = this.test.id
      }
      console.log(data)
      this.$store.dispatch('UpdateProviderContract', {
        data1: data,
        id: this.$route.params.id
      })
      //   }
      //   this.typeOfContract = "";
      //   this.endOfContract = "";
      //   this.dateOFContract = "";
      //   this.contractNumber = "";
      //   this.provider_name = "";
      //   this.$v.$reset();
    }
  }
}
</script>

<style scoped>
.input__wrapper {
  display: flex;
  flex-wrap: wrap;
}
.v-input__prepend-outer /deep/ .v-input__icon /deep/ .v-input__icon--prepend {
  display: none !important;
}

.col-4 {
  padding-left: 0;
}
</style>
